export type ApiVersion = "Beta" | "Prod" | "RC";
export type AwsConfiguration = {
	host: string;
	apiVersion: ApiVersion;
	cloudVersion: ApiVersion;
	machineMode: boolean;
	stripeApiKey: string;
	apiKey: string;
	region: string;
	mapBoxApiKey: string;
	esriApiKey: string;
};

export const awsConfiguration: AwsConfiguration = {
	host: "a8m0fg7yvuu5k-ats.iot.us-east-1.amazonaws.com", // 'YourAwsIoTEndpoint', e.g. 'prefix.iot.us-east-1.amazonaws.com'
	apiVersion: "RC",
	cloudVersion: "Beta",
	machineMode: false,
	stripeApiKey: "pk_live_W3k2Z6Qf6jIMvKg7DxHdOVbZ00GNmJZLbx",
	apiKey: "AIzaSyDa6ZFf53TRQWAUad0vTl-2HZ5Hn82pf2c",
	region: "us-east-1",
	mapBoxApiKey:
		"sk.eyJ1IjoicHJhbnlzYSIsImEiOiJja3U2MmNhNGkxcHBpMnFuaWg5OTQ0ZGh4In0.TdPCb8ZOXG_XDUWE8Rwe0g",
	esriApiKey:
		"AAPK83d1d9964a9243e6a59bac3a6119bf47VaVA5uW-nqqXZsxDWiyy1j7yWd2UwCpXlKbmSYWdo942RQ2QZvHMGccPVEwWUbOQ",
};

export const AppConfig = {
	Beta: "https://uej1di59a8.execute-api.us-east-1.amazonaws.com/Beta",
	Prod: "https://uej1di59a8.execute-api.us-east-1.amazonaws.com/Prod",
	RC: "https://uej1di59a8.execute-api.us-east-1.amazonaws.com/RC",
};
export const getApiUrl = (apiVersion: ApiVersion) => AppConfig[apiVersion];
