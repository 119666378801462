<script setup lang="ts">
	import { DialogLayout } from "@jca/libs/ui";

	defineProps<{
		title: string;
		text: string;
		cancelText?: string;
		okText?: string;
		loading?: boolean;
	}>();

	const emit = defineEmits<{
		(e: "cancel"): void;
		(e: "confirm"): void;
	}>();
</script>

<template>
	<DialogLayout class="dialog">
		<template #title>
			{{ title }}
		</template>
		<p>{{ text }}</p>
		<template #actions>
			<div class="flex flex-1 gap-2">
				<button
					class="button flex-1"
					@click="emit('cancel')"
				>
					{{ cancelText }}
				</button>
				<button
					:disabled="loading"
					class="button button-error flex-1"
					@click="emit('confirm')"
				>
					{{ okText }}
				</button>
			</div>
		</template>
	</DialogLayout>
</template>
<style scoped>
	.dialog {
		max-width: 20rem;
	}
</style>
