import type { ResourcesConfig } from "aws-amplify";

const awsWeb: ResourcesConfig = {
	Auth: {
		Cognito: {
			// REQUIRED - Amazon Cognito Identity Pool ID
			identityPoolId: "us-east-1:250b3b10-812c-42b2-bbe7-5c175ed5c36d",
			// OPTIONAL - Amazon Cognito User Pool ID
			userPoolId: "us-east-1_j2zwt6der",
			// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
			userPoolClientId: "7t697i8h5pdg2jgqd0s1348vqk",
		},
	},
};

export default awsWeb;
