import { awsConfiguration, type ApiVersion } from "@/aws-configuration";
import { inject, ref, watch, type App, type Ref } from "vue";

export type Settings = {
	apiVersion: Ref<ApiVersion>;
	organizationId: Ref<string | null>;
};

const apiVersion = ref(
	(localStorage.getItem("apiVersion") as ApiVersion) ??
		awsConfiguration.apiVersion,
);
watch(apiVersion, newVal => localStorage.setItem("apiVersion", newVal));

const organizationId = ref(localStorage.getItem("organizationId") ?? "");
watch(organizationId, newVal => localStorage.setItem("organizationId", newVal));

export const useSettings = () => inject<Settings>("app-settings")!;

export function getApiVersionSetting() {
	try {
		return (apiVersion.value ?? awsConfiguration.apiVersion) as ApiVersion;
	} catch (e) {
		console.log(e);
		return awsConfiguration.apiVersion;
	}
}

export const provideSettings = (app: App) => {
	app.provide("app-settings", {
		apiVersion,
		organizationId,
	});
	return { apiVersion, organizationId };
};
