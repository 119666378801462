import { getApiUrl } from "@/aws-configuration";
import { provideSettings } from "@/composables/settings";
import {
	configureAWS,
	initClientReactive,
	initCloudMqtt,
	isUserFlightPathAdmin,
	setCustomerId,
	setOrganizationId,
	useCustomerId,
} from "@jca/libs/api";
import { setConfirmComponent } from "@jca/libs/confirm";
import "@jca/libs/theming";
import { loadIcons } from "@jca/libs/ui";
import * as Sentry from "@sentry/vue";
import ConfirmDialogContent from "@shared/components/ConfirmDialogContent.vue";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { computed, createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import { awsConfiguration } from "./aws-configuration";
import awsExports from "./aws-exports";
import router from "./router";

const app = createApp(App);

if (import.meta.env.PROD) {
	Sentry.init({
		Vue: app,
		dsn: "https://e8c0a836e101a2e5114a5f5763f977bd@o4506507993481216.ingest.sentry.io/4506792051933184",
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

app.use(router);
app.use(VueQueryPlugin);

const i18n = createI18n({
	locale: "en",
	legacy: false,
});
app.use(i18n);

configureAWS(awsExports);
const { apiVersion, organizationId } = provideSettings(app);
initCloudMqtt(awsConfiguration.region, awsConfiguration.host);
setConfirmComponent(app, ConfirmDialogContent);

//Set the organization id if the user is an admin
if (await isUserFlightPathAdmin()) {
	setOrganizationId(organizationId.value ?? useCustomerId);
} else {
	setCustomerId(false);
}

initClientReactive(
	computed(() => {
		return getApiUrl(apiVersion.value);
	}),
	true,
);

const icons = import.meta.glob("@shared/assets/icons/**/*.svg", {
	query: "raw",
	import: "default",
	eager: true,
}) as Record<string, string>;
await loadIcons(icons, app);

app.mount("#app");
